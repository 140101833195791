<template>
  <div>
    <!--Breadcrumb button-->
    <breadcrumb-button>
<!--      <template #beforeDropdown>
        <b-button variant="success" class="btn-icon" v-b-tooltip v-bind:title="$t('operations.add')"
                  @click="onMonetariesSelected(null)"
        >
          <i class="fa-solid fa-credit-card"></i>
        </b-button>
      </template>-->
      <template #items>
        <b-dropdown-item @click="openColumnsModal">
          <feather-icon
              icon="CheckSquareIcon"
              size="16"
          />
          <span class="align-middle ml-50">{{ $t('columns.self') }}</span>
        </b-dropdown-item>
      </template>

    </breadcrumb-button>
    <!--Filters-->
    <filter-card v-model="filter" v-bind:fields="filterFields" search file-export @export="createExcelFile"/>
    <!--Table Card-->
    <b-card no-body>
      <b-card-header class="pb-50 d-flex">
        <h5>{{ $t('menu.monetaries') }}</h5>
        <div>
          <transaction-toggle class="mb-1" v-model="transactionType"/>
        </div>
      </b-card-header>
      <b-card-body>
        <monetary-table ref="monetariesTable" v-bind:filters="filter" v-bind:columns="columns" :has-selection="false"
                        @selected="onMonetariesSelected" :lazy="true"
         :trans-type="transactionType"/>
      </b-card-body>
    </b-card>
    <!--Modal-->
    <columns-modal ref="columns-modal" column-key="monetaries" v-model="columns"
                   v-bind:columns-names="columnsNames"
    />
    <monetaries-modal ref="monetaries-modal"/>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import BreadcrumbButton from '@/components/widget/breadcrumbButton';
import MonetariesSearchRequest from '@/model/monetary/MonetarySearchRequest';
import ColumnsModal from '@/components/modal/columnsModal';
import Monetaries from '@/model/monetary/Monetary';
import mixinPermissions from '@/components/mixin/mixinPermissions';
import mixinBase from '@/components/mixin/mixinBase';
import MonetariesModal from '@/components/modal/monetaryModal';
import FilterCard from '@/components/widget/FilterCard';
import MonetariesFilterFields from '@/model/filter/MonetaryFilterFields';
import MonetaryTable from '@/components/tables/MonetariesTable';
import TransactionToggle from '@/views/monetaries/TransactionToggle';

export default {
  name: 'MonetaryView',
  components: {
    MonetaryTable,
    FilterCard,
    MonetariesModal,
    ColumnsModal,
    BreadcrumbButton,
    TransactionToggle
  },
  mixins: [mixinBase, mixinPermissions],
  data() {
    return {

      transactionType: 'deposits',
      columnsNames: Object.keys(Monetaries()),
      filter: MonetariesSearchRequest(),
      filterLoaded: false,
      columns: [],
      filterFields: null,
    };
  },
  watch: {
    transactionType(newVal, oldVal) {
      console.log('type is', newVal);
      if (newVal === 'all') {
        this.filter['type'] = null;
      } if (newVal === 'deposits') {
        this.filter['type'] = 'DEPOSIT';
      } else if (newVal === 'withdrawals') {
        this.filter['type'] = 'WITHDRAWAL';
      }
    }
  },
  async created() {
    this.filter['type'] = 'DEPOSIT';
    await this.refreshData();
    this.filterFields = MonetariesFilterFields();
  },
  computed: {
    ...mapGetters('data', ['allOrganizations']),
  },
  methods: {
    ...mapActions('monetary', ['refreshData']),
    openColumnsModal() {
      this.$refs['columns-modal'].showModal();
    },
    onMonetariesSelected(selectedId) {
      // console.log("onMonetariesSelected", selectedId)
      this.$refs['monetaries-modal'].showModal(selectedId);
    },
    createExcelFile(){
      this.$refs["monetariesTable"].createExcelFile()
    },
  }
};
</script>

<style>
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #dfe5fb;
  border: none;
  color: #394066;
  text-transform: lowercase;
  font-variant: small-caps;
  height: 10px;
}
</style>
